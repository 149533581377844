.welcome {
    min-height: 100vh;
}

/*.welcome-header {*/
/*    background-color: #282c34;*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: calc(10px + 2vmin);*/
/*    color: white;*/
/*}*/

/*.welcome-link {*/
/*    color: #61dafb;*/
/*}*/
